<template>
  <form class="form w-100" id="kt_sponsor_change_form">
    <b-card>
      <!-- NOTE: SPONSOR NAME -->
      <div class="form-group w-100">
        <label class="col-xl-3 col-lg-3 col-form-label text-alert">Sponsor Name</label>
        <div class="col-lg-9 col-xl-6">
          <input class="form-control form-control-lg mb-2" v-model="headline" name="headline" ref="headline" />
        </div>
      </div>
      <!-- NOTE: BUTTON TEXT -->
      <div class="form-group w-100">
        <label class="col-xl-3 col-lg-3 col-form-label text-alert">Button Text</label>
        <div class="col-lg-9 col-xl-6">
          <input class="form-control form-control-lg mb-2" v-model="buttonText" name="buttonText" ref="buttonText" />
        </div>
      </div>
      <!-- NOTE: BUTTON LINK -->
      <div class="form-group w-100" v-if="buttonText">
        <label class="col-xl-3 col-lg-3 col-form-label text-alert">Button Link</label>
        <div class="col-lg-9 col-xl-6">
          <input class="form-control is-invalid" v-model="buttonLink" name="buttonLink" ref="buttonLink" />
          <div class="invalid-feedback">Link is required</div>
        </div>
      </div>
      <!-- NOTE: DESCRİPTİON -->
      <div class="form-group w-100">
        <label class="col-xl-3 col-lg-3 col-form-label text-alert">Description</label>
        <div class="col-lg-9 col-xl-6">
          <textarea class="form-control form-control-lg mb-2" v-model="text" name="text" ref="text" />
        </div>
      </div>
      <!-- NOTE: IMAGE -->
      <div class="image-input image-input-outline" :style="{ backgroundImage: `url(${photo})` }">
        <div class="image-input-wrapper text-left"></div>
        <label
          class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
          data-action="change"
          data-toggle="tooltip"
          title=""
        >
          <i class="fa fa-pen icon-sm text-muted"></i>
          <input type="file" accept=".png, .jpg, .jpeg" @change="onFileChange($event)" />
        </label>
      </div>
      <template #footer>
        <!-- <button type="reset" class="btn btn-primary mr-2 right" @click="saveChanges" ref="kt_save_changes">
          Save Changes
        </button> -->
        <button class="btn btn-primary mr-2 right" ref="kt_save_changes">Save Changes</button>
      </template>
    </b-card>
  </form>
  <!-- <div>
    <b-row>
      <b-col xl="12" lg="12" md="12" sm="12" cols="12">
        <b-card class="card-custom h-100" footer-tag="footer" footer-class="text-right">
          <b-row>
            <b-col xl="6" lg="12" md="12" sm="12" cols="12" class="mb-5">
              <label class="text-muted ml-1">Sponsor Name</label>

              <b-form-input v-model="headline" ref="headline"></b-form-input>
            </b-col>
            <b-col xl="6" lg="12" md="12" sm="12" cols="12" class="mb-5">
              <label class="text-muted ml-1">Link Text</label>
              <b-form-input v-model="buttonText"></b-form-input>
            </b-col>
            <b-col xl="6" lg="12" md="12" sm="12" cols="12" class="mb-5" v-if="buttonText">
              <label class="text-muted ml-1">Link</label>
              <b-form-input class="form-control" v-model="buttonLink"></b-form-input>
            </b-col>

            <b-col xl="12" lg="12" md="12" sm="12" cols="12" class="mb-5">
              <label class="text-muted ml-1 mt-5">Description</label>
              <b-form-textarea rows="8" v-model="text"></b-form-textarea
            ></b-col>
            <label class="text-muted ml-5 mt-5">Image</label>
            <b-col xl="12" lg="12" md="12" sm="12" cols="12" class="text-left mb-5">
              <div class="image-input image-input-outline" :style="{ backgroundImage: `url(${photo})` }">
                <div class="image-input-wrapper text-left"></div>
                <label
                  class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="change"
                  data-toggle="tooltip"
                  title=""
                >
                  <i class="fa fa-pen icon-sm text-muted"></i>
                  <input type="file" accept=".png, .jpg, .jpeg" @change="onFileChange($event)" />
                </label>
              </div> </b-col
          ></b-row>
          <template #footer>
            <b-button variant="primary" class="font-weight-bold px-10" @click="saveChanges"> Add </b-button>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </div> -->
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Swal from 'sweetalert2'
import KTUtil from '@/assets/js/components/util'
import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core'
// FormValidation plugins
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger'
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap'
import SubmitButton from '@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton'

import SponsorService from '../../../core/services/sponsors'

export default {
  name: 'sponsors-new',
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Sponsors' }])

    const form_validation = KTUtil.getById('kt_sponsor_change_form')

    this.fv = formValidation(form_validation, {
      fields: {
        headline: {
          validators: {
            notEmpty: {
              message: 'Sponsor Name is required',
            },
          },
        },
        text: {
          validators: {
            notEmpty: {
              message: 'Description is required',
            },
          },
        },
        current_photo: {
          validators: {
            notEmpty: {
              message: 'Photo is required',
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    })

    this.fv.on('core.form.valid', async () => {
      const submitButton = this.$refs['kt_save_changes']
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right')

      if (this.current_photo === null) {
        Swal.fire('Photo is required', '', 'warning')
      }
      console.log(`this.imageType`, this.imageType)

      const uploadPhoto = await SponsorService.uploadPhoto(this.imageType)

      console.log(`uploadPhoto`, uploadPhoto)
      submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right')
    })

    this.fv.on('core.form.invalid', () => {
      Swal.fire({
        title: '',
        text: 'Please, provide correct data!',
        icon: 'error',
        confirmButtonClass: 'btn btn-secondary',
      })
    })
  },
  computed: {
    photo() {
      return this.current_photo == null ? this.default_photo : this.current_photo
    },
  },
  data() {
    return {
      text: '',
      headline: '',
      buttonText: '',
      buttonLink: '',
      editorConfig: {
        // The configuration of the editor.
      },
      default_photo: '',
      current_photo: null,
      imageType: {
        fileSize: '',
        fileName: '',
        height: '',
        width: '',
        uri: '',
        type: '',
      },
    }
  },
  components: {},
  methods: {
    onFileChange(e) {
      const file = e.target.files[0]

      if (typeof FileReader === 'function') {
        const reader = new FileReader()

        reader.readAsDataURL(file)
        reader.onload = event => {
          const image = new Image()
          image.src = event.target.result

          const imageType = {
            fileSize: e.target.files[0].size,
            fileName: e.target.files[0].name,
            height: null,
            width: null,
            uri: event.target.result,
            type: e.target.files[0].type,
          }
          image.onload = function () {
            // const imageType = {
            //   fileSize: e.target.files[0].size,
            //   fileName: e.target.files[0].name,
            //   height: this.height,
            //   width: this.width,
            //   uri: event.target.result,
            //   type: e.target.files[0].type,
            // }
            imageType.height = this.height
            imageType.width = this.width
          }
          this.imageType = imageType
          console.log(`this.imageType`, this.imageType)
          this.current_photo = event.target.result
        }
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    async saveChanges() {
      const user = localStorage.getItem('scooble_user')
      const sponsorType = {
        headline: this.headline,
        text: this.text,
        buttonLink: this.buttonLink,
        buttonText: this.buttonText,
        photoUrl: '',
        userId: JSON.parse(user)._id,
      }

      // Upload Photo
      console.log(`this.imageType`, this.imageType)
      console.log(`typeof this.imageType`, typeof this.imageType)

      console.log(`sponsorType`, sponsorType)
      if (sponsorType.buttonText !== '' && sponsorType.buttonLink == '') {
        return Swal.fire({
          title: 'This field is not filled.',
          text: 'Button link',
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary',
        })
      }

      // const createdSponsor = await SponsorService.create(sponsorType)
      // console.log(`createdSponsor`, createdSponsor)
    },
  },
}
</script>

<style>
.image-input-wrapper {
  width: 250px;
  height: 250px;
}
</style>
