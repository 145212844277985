import ApiService from '../api.service'

const { put, post, get } = ApiService
const endPoint = '/sponsor'

class SponsorService {
  async getAll() {
    return await get(endPoint)
  }

  async create(data) {
    return await post(endPoint, data)
  }

  async delete(sponsorId, userId) {
    // FIXME: yeni parametler gelicek
    console.log(`sponsorId`, sponsorId)
    console.log(`userId`, userId)
    return await ApiService.delete(`${endPoint}/delete`, { data: { id: sponsorId, userId } })
  }

  async update(sponsorId, userId, sponsorDetails) {
    return await put(`${endPoint}/update-sponsor`, { id: sponsorId, userId, sponsorDetails })
  }
  async uploadPhoto({ uri, fileName, width, height }) {
    const formData = new FormData()
    formData.append('image', { uri, name: fileName })
    formData.append('height', height)
    formData.append('width', width)
    return await post('/file/photo', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  }
}

export default new SponsorService()
